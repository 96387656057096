import React from 'react';

import {
  Dialog,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Stack,
} from '@mui/material/';
import StyledIcon from './StyledIcon.jsx';
import stationTypes from '../stations.js';

import { Close as CloseIcon } from '@mui/icons-material';

export default function Webcams({
  setWebcamDialogOpen,
  setSelectedStation,
  webcamDialogOpen,
  webcamDialogData,
}) {
  const theme = useTheme();

  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const selectedStationType = stationTypes.find(
    (stationType) => stationType?.type === 'webcams'
  );

  function handleWebcamPanelClose() {
    setWebcamDialogOpen(false);
    setSelectedStation();
  }

  return (
    <Dialog
      fullScreen={mobileView}
      maxWidth="xl"
      fullWidth
      onClose={() => handleWebcamPanelClose()}
      open={webcamDialogOpen}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.secondary.main,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.primary.dark }}
        padding={1}
      >
        <Box
          sx={{
            height: '35px',
            width: '35px',
            borderRadius: '30px',
            border: `2px solid ${theme.palette.secondary.main}`,
            margin: '3px',
            paddingLeft: '2px',
          }}
        >
          <StyledIcon filename={selectedStationType.icon} height={20} width={27} />
        </Box>
        <Stack alignItems="center">
          <Typography color="secondary">{webcamDialogData.title}</Typography>
        </Stack>
        <IconButton onClick={() => handleWebcamPanelClose()} aria-label="close">
          <CloseIcon color="secondary" />
        </IconButton>
      </Stack>
      <iframe src={webcamDialogData.extra_station_data} style={{ height: '100vh' }} />
    </Dialog>
  );
}
