import React from 'react';

import {
  Dialog,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material/';

import { Close as CloseIcon } from '@mui/icons-material';
import StyledIcon from './StyledIcon.jsx';
import mapLayers from '../mapLayers.js';

export default function VariableSelectMenu({
  variableSelectOpen,
  setVariableSelectOpen,
  setSelectedVariableName,
  selectedVariableName,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={mobileView}
      open={variableSelectOpen}
      sx={{ pointerEvents: 'auto' }}
      onClose={(e, reason) => reason === 'backdropClick' && setVariableSelectOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      }}
    >
      <Toolbar>
        <Typography
          color="secondary"
          sx={{ ml: 2, flex: 1 }}
          variant="h6"
          component="div"
        >
          Conditions
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setVariableSelectOpen(false)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List sx={{ margin: '0px 25px', minWidth: mobileView ? '' : '400px' }}>
        {mapLayers.map((mapLayer, index) => {
          return (
            <React.Fragment key={index}>
              <ListItemButton
                onClick={() => {
                  window.clarity('event', 'Layer -> ' + mapLayer.displayName);
                  setSelectedVariableName(mapLayer.variableName);
                  setVariableSelectOpen(false);
                }}
                sx={{
                  border:
                    selectedVariableName === mapLayer.variableName && `2px solid white`,
                  borderRadius: '10px',
                  boxSizing: 'border-box',
                }}
                color="secondary"
              >
                <ListItemText sx={{ flex: 1 }}>{mapLayer.displayName}</ListItemText>
                <ListItemIcon>
                  <StyledIcon filename={mapLayer.icon} />
                </ListItemIcon>
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </Dialog>
  );
}
