import React from 'react';
import { Fab, Stack, Typography, useTheme } from '@mui/material';
import {
  generateGradientScale,
  getColorScale,
  minScreenHeight,
  useWindowSize,
} from '../../utilities';
import units from '../../units.js';

export default function Legend({ selectedMapLayer, settings, changeUnits }) {
  const theme = useTheme();
  let { scale, unit, variableName } = selectedMapLayer;
  const unitCategory = units.find((e) => e.name === unit)?.category;
  const size = useWindowSize();
  const screenIsShort = size.height < minScreenHeight;
  const legendStepHeight = screenIsShort ? 14.3 : 25;
  const legendStepWidth = 24;

  const unitSelected = settings.units[unitCategory];

  const { display } = units.find((e) => e.name === unitSelected);

  scale = getColorScale(variableName, settings.units);

  const gradientScale = generateGradientScale(scale);

  // extend end colors so that text lines up with correct values
  gradientScale.unshift(gradientScale[0]);
  gradientScale.push(gradientScale[gradientScale.length - 1]);

  return (
    <Fab
      color="primary"
      variant="extended"
      sx={{
        width: `${legendStepWidth * 2}px`,
        height: `${legendStepHeight * 10 + 25}px`,

        borderRadius: '50px',
        pointerEvents: 'auto',
        textTransform: 'none',
        transform: 'translate(0)',
        transformStyle: 'preserve-3d',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          inset: '-2px',
          transform: 'translate3d(0px, 0px, -1pc)',
          background: `linear-gradient(to top, transparent 15%, transparent 20% )`,
          borderRadius: '55px',
          boxShadow: 7,
        },
        boxShadow: 'none',
      }}
      onClick={() => {
        window.clarity('event', 'Change units from legend');
        changeUnits(variableName);
      }}
    >
      <Stack>
        <div
          style={{
            width: `${legendStepWidth * 2}px`,
            height: `${legendStepHeight * 10}px`,
            background: `linear-gradient(to bottom, ${gradientScale.toString()})`,
            borderRadius: '30px',
            marginTop: screenIsShort ? '-10px' : '0px',
          }}
        />
        <Stack
          spacing={0.85}
          sx={{
            position: 'absolute',
            top: '10px',
            width: legendStepWidth * 2,
            height: legendStepHeight * (screenIsShort ? 5 : 10),
          }}
        >
          {scale?.map((step, index) => {
            if (index % (screenIsShort ? 4 : 2) === 0) {
              return (
                <Typography key={index} variant="caption">
                  {`${+step?.value}${index === 0 ? '+' : ''}`}
                </Typography>
              );
            }
          })}
        </Stack>
        <Typography
          variant="caption"
          sx={{
            marginTop: '-3px',
            height: legendStepHeight,
            fontSize: '0.875rem',
            textDecoration: 'underline',
            textDecorationStyle: 'dotted',
            textDecorationThickness: '1.5px',
            paddingBottom: '3px',
          }}
        >
          {display}
        </Typography>
      </Stack>
    </Fab>
  );
}
