import { Dialog, Box, Typography, Stack } from '@mui/material/';
import React from 'react';
import theme from './theme';

function Fallback({ open }) {
  return (
    <Dialog
      fullScreen
      open={open}
      PaperProps={{
        style: {
          background: `linear-gradient(to bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
        },
      }}
    >
      <Box
        m="auto"
        color="white"
        flexDirection="column"
        // style={{ backgroundColor: theme.palette.primary.dark }}
      >
        <Stack
          spacing={4}
          direction="column"
          alignItems={'center'}
          justifyContent="space-evenly"
        >
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            Oops!
          </Typography>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Something went wrong. The OceanConnect&trade; team has been notified.
          </Typography>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Please refresh your browser.
          </Typography>
          <Stack direction="row" spacing={0}>
            <Box
              component="img"
              src={require('./Images/oceanconnectlogo-color-03.svg')}
              height="100px"
              width="50px"
              margin="auto"
            />
            <Typography sx={{ cursor: 'default', lineHeight: 0.5 }}>&trade;</Typography>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
export default Fallback;
