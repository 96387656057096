import { useRef, useEffect, useState } from 'react';

import mapLayers from './mapLayers.js';
import units from './units.js';

export const server =
  process.env.API_URL || 'https://a0t6h1frq6.execute-api.us-east-1.amazonaws.com/dev';
export const mapboxToken =
  process.env.REACT_APP_MAPBOX_TOKEN ||
  'pk.eyJ1IjoiaGFrYWkiLCJhIjoiY2x0ZGgycTU0MDNpMzJrcndxcDcwb21zdSJ9.db6Xl7giQcD10mcJV5xyag';

export const minScreenHeight = 550;
export const minScreenWidth = 500;

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getPacificTime(param) {
  const date = param ? new Date(param) : new Date();
  return date.toLocaleString('en', {
    timeZone: 'America/Vancouver',
  });
}

export function getArrowDirection(selectedVariableName, direction) {
  return direction;
}

export const defaultSettings = {
  units: {
    speed: 'kmh',
    temperature: 'c',
    height: 'm',
  },
  showColorStrip: false,
  useCrosshair: true,
  showAnimations: true,
  colorData: false,
};
export const defaultMapCenter = [-123.3, 49];
export const defaultMapZoom = 6.75;

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function generateDateTime(date) {
  // eg 2023-03-21T07:00:05.000Z
  const isoDate = date.toISOString();

  const yearString = isoDate.substring(0, 4);
  const monthString = isoDate.substring(5, 7);
  const dayString = isoDate.substring(8, 10);
  const hourString = isoDate.substring(11, 13);

  const modelDateTime = `${yearString}${monthString}${dayString}_${hourString}`;

  return modelDateTime;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const daysOfTheWeekShortName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export function formatAMPM(date) {
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  const strTime = `${hours} ${ampm}`;
  return strTime;
}

export function formatAMPMwithMinutes(date) {
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function degToCompass(num) {
  const val = Math.floor(num / 22.5 + 0.5);
  const arr = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ];
  return arr[val % 16];
}

export function isSameDaySameHour(timeA, timeB) {
  const dateA = new Date(timeA).toDateString();
  const hourA = new Date(timeA).getHours();
  const dateB = new Date(timeB).toDateString();
  const hourB = new Date(timeB).getHours();
  return dateA === dateB && hourA === hourB;
}

export function metersPerSecondToKMPerHour(metersPerSecond) {
  return metersPerSecond * 3.6;
}

export function roundScale(scale) {
  return scale.map((step) => {
    const { color, value } = step;
    return { color, value: roundNumber(value) };
  });
}

export function toFixedScale(scale, fix) {
  return scale.map((step) => {
    const { color, value } = step;
    return { color, value: +value.toFixed(fix) };
  });
}

export function getColorScale(variableName, unitsSettings) {
  const selectedMapVar = mapLayers.find((e) => e.variableName === variableName);
  let { scale, unit } = selectedMapVar;

  const { multiplier } = getNewUnits(unit, unitsSettings);

  scale = scale.map((step) => {
    return { color: step.color, value: multiplier(step.value) };
  });

  if (['wave', 'seaSurfaceHeight', 'current'].includes(variableName)) {
    // toFixed
    scale = toFixedScale(scale, 1);
  } else if (['airTemperature', 'potentialTemperature', 'wind'].includes(variableName)) {
    // Math.round
    scale = roundScale(toFixedScale(scale, 0));
  }

  return scale;
}

export function generateGradientScale(scale) {
  const gradientScale = [];
  scale?.forEach((step) => {
    gradientScale.push(`${step.color}`);
  });
  return gradientScale;
}

export function getVariableTimeseriesData(dataArr, variableName, stationID) {
  return dataArr.map((d) => {
    const tempDate = new Date(d.date);
    return {
      date: stationID ? tempDate : makeMinutesZero(tempDate),
      isLive: d.isLive,
      ...d.data[variableName],
    };
  });
}

export function checkLandOrWater(map, point) {
  const features = map.queryRenderedFeatures(point);
  let landOrWater;
  if (features.length) {
    landOrWater = features.pop().layer?.id === 'water' ? 'water' : 'land';
  }
  // no features are returned on transparent layers
  else landOrWater = 'land';

  return landOrWater;
}

export function getNewUnits(unit, unitsSettings) {
  const { category } = units.find((e) => e.name === unit);
  const newUnits = unitsSettings[category];
  const {
    multiplier,
    display: displayUnits,
    name,
  } = units.find((e) => e.name === newUnits);
  return { multiplier, displayUnits, name };
}

export function roundNumber(x) {
  const number = Number(x);
  if (number < 10) {
    return Number(number.toFixed(1));
  } else {
    return Number(number.toFixed(0));
  }
}

export function formatNumberGenerator(unitsSelected) {
  let decimals = false;
  let multiplier = (x) => x;
  const res = units.find((e) => e.name === unitsSelected);
  if (res) {
    decimals = res.decimals;
    multiplier = res.multiplier;
  }

  return (x) => {
    if (x === undefined || isNaN(x) || x === null) return NaN;
    if (decimals) return roundNumber(multiplier(x));
    return Math.round(multiplier(x));
  };
}

export function RGBToHSL(rgb) {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
}

export function hexToRgb(hex) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
}

function rgbToLuminance(r, g, b) {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrastRatio(l1, l2) {
  const lighter = Math.max(l1, l2);
  const darker = Math.min(l1, l2);
  return (lighter + 0.05) / (darker + 0.05);
}

export function colorContrasts(rgbColorA, rgbColorB) {
  // Convert theme color from hex to RGB
  const colorRgbB = rgbColorB.match(/\d+/g).map(Number);

  // Extract RGB values from the input color
  const colorRgbA = rgbColorA.match(/\d+/g).map(Number);

  // Calculate luminance values for both colors
  const luminanceA = rgbToLuminance(...colorRgbA);
  const luminanceB = rgbToLuminance(...colorRgbB);

  // Calculate the contrast ratio
  const ratio = contrastRatio(luminanceB, luminanceA);
  // Check if the contrast ratio is above the recommended threshold (e.g., 4.5 for normal text)
  return ratio >= 3;
}

export function makeMinutesZero(date) {
  date?.setMinutes(0);
  date?.setSeconds(0);
  date?.setMilliseconds(0);
  return date;
}

export function getTileName(modelLongName, dateTime, tileTimesLookup, variableName) {
  const dateTime2 = dateTime.replace(/_/g, 'T') + 'Z';
  const modelLookup = tileTimesLookup.find((e) => e.model === modelLongName);

  const res = modelLookup?.model_data.filter((e) => e.model_time === dateTime2);
  let modelRun;
  if (res && res.length) {
    modelRun = res[0].model_run;
  } else {
    modelRun = 'no_tile_found';
    console.error('no model run found for ', modelLongName, dateTime2);
  }

  // hour is modelTime minus modelRun, convert each to datetiome first using modelDateHourToISODateHour;

  const hour = Math.floor(
    (new Date(modelDateHourToISODateHour(dateTime)) -
      new Date(modelDateHourToISODateHour(modelRun.replace('T', '_').replace('Z', '')))) /
      (1000 * 60 * 60)
  );
  const tileName = `${modelLongName}_${variableName}_${modelRun}_${hour}_${dateTime2}`;
  return { tileName, modelRun };
}

function modelDateHourToISODateHour(modelDateHour) {
  // eg converts 20230313_13 to 2023-03-13T13:00:00.000Z
  const [modelDateStr, hour] = modelDateHour.split('_');

  const year = modelDateStr.slice(0, 4);
  const month = modelDateStr.slice(4, 6);
  const day = modelDateStr.slice(6);

  const modelIsoString = `${[year, month, day].join('-')}T${hour}:00:00.000Z`;

  return new Date(Date.parse(modelIsoString));
}

// make a function to cut off text at length and add ...
export function truncateText(text, length) {
  return text.length > length ? text.slice(0, length) + '...' : text;
}
