import React from 'react';

import { Box, useMediaQuery, useTheme, Stack, Typography, Grid } from '@mui/material';
import mapLayers from '../../mapLayers.js';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { StyledDrawerVariableSwitchButton } from '../StyledComponents.jsx';
import DataElementStrip from './DataElementStrip.jsx';
import { truncateText } from '../../utilities';
export default function DataDrawer({
  selectedVariableName,
  setDataPinDrawerOpen,
  setDataPanelOpen,
  moveSelectedVariable,
  drawerData,
  settings,
  loadingDrawerData,
  changeUnits,
  dataDrawerHidden,
  setDataDrawerHidden,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { noDataReason } = drawerData;
  const { title: tideStationTitle } = drawerData[selectedVariableName] || {};

  const selectedMapVar = mapLayers.find((e) => e.variableName === selectedVariableName);
  const borderRadius = mobileView ? '0px' : '25px';

  return (
    <Box
      sx={{
        // borderTop: mobileView ? `0px solid ${theme.palette.primary.dark}` : 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        pointerEvents: 'none',
        position: 'absolute',
        // bottom: '63px',
        zIndex: 1000,
        // marginTop: '45px',
        width: mobileView ? '100%' : '500px',
        top: '0px',
        // padding: window.innerWidth > 400 ? '0px 10px 0px 10px' : '0px 0px 0px 0px',
        // marginTop: mobileView ? '30px' : '40px',
        // maxWidth: '400px',
        left: '50%',
        transform: 'translate(-50%, 0px)',
      }}
    >
      {/* <ArrowIndicator color={theme.palette.primary.dark} /> */}
      {!dataDrawerHidden && (
        <Stack
          elevation={5}
          sx={{
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'white',
            borderRadius,
            pointerEvents: 'auto',
            // overflow: 'hidden',
            height: `100%`,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ height: '100%x' }}
            alignItems="center"
          >
            <StyledDrawerVariableSwitchButton
              color="primary"
              onClick={() => {
                window.clarity('event', 'Expanded Drawer change variable');
                moveSelectedVariable('right');
              }}
              disabled={drawerData?.length === 1}
              elevation={5}
            >
              <ChevronLeft sx={{ fontSize: '50px', color: 'secondary' }} />
            </StyledDrawerVariableSwitchButton>
            <Typography sx={{ textAlign: 'center' }}>
              {tideStationTitle
                ? `Tides: ${truncateText(tideStationTitle, 15)}`
                : selectedMapVar.displayName}
            </Typography>
            <StyledDrawerVariableSwitchButton
              color="primary"
              onClick={() => {
                window.clarity('event', 'Expanded Drawer change variable');
                moveSelectedVariable('left');
              }}
              disabled={drawerData?.length === 1}
              elevation={5}
            >
              <ChevronRight sx={{ fontSize: '50px', color: 'secondary' }} />
            </StyledDrawerVariableSwitchButton>
          </Stack>
          <Box
            sx={{
              backgroundColor: `${theme.palette.primary.dark}`,
              height: '70px',
              // paddingLeft: '20px',
              borderRadius,
            }}
          >
            <DataElementStrip
              selectedVariableName={selectedVariableName}
              variableData={drawerData[selectedVariableName] || {}}
              dataLoading={loadingDrawerData}
              changeUnits={changeUnits}
              minTide={drawerData?.seaSurfaceHeight?.min_height}
              maxTide={drawerData?.seaSurfaceHeight?.max_height}
              settings={settings}
              width="100%"
              useDataColor={true}
              setDataPanelOpen={setDataPanelOpen}
              backgroundColor={theme.palette.primary.dark}
              showForecastButton={true}
              noDataReason={noDataReason}
            />
          </Box>
        </Stack>
      )}
      <Grid container>
        <Grid item xs={3} />

        {/* {!dataDrawerHidden &&
          <Grid item container xs={3} justifyContent="flex-end">
            <StyledDrawerHeaderButton
              variant="contained"
              startIcon={<SsidChartRounded />}
              onClick={() => setDataPanelOpen(true)}
            >
              forecast
            </StyledDrawerHeaderButton>
          </Grid>} */}
        {/* <Grid item container xs={4} justifyContent="flex-start">
          <StyledDrawerHeaderButton
            variant="contained"
            endIcon={dataDrawerHidden ? <></>:<Close /> }
            onClick={() => setDataDrawerHidden(!dataDrawerHidden)}
          >
            {dataDrawerHidden ? "show values" : "hide"}
          </StyledDrawerHeaderButton>
        </Grid> */}
        <Grid item xs={3} />
      </Grid>
    </Box>
  );
}
