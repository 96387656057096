import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, useTheme, useMediaQuery, Divider } from '@mui/material';
import mapLayers from '../../mapLayers.js';
import VariableInsightBottom from './VariableInsightsBottom.jsx';

import {
  formatAMPM,
  formatAMPMwithMinutes,
  getNewUnits,
  formatNumberGenerator,
  daysOfTheWeekShortName,
  truncateText,
} from '../../utilities.js';

import clonedeep from 'lodash.clonedeep';
import DataElementStrip from '../DataDrawer/DataElementStrip.jsx';

export default function VariableInsights({
  selectedVariableName,
  scrollTime,
  varData,
  liveData,
  variableInsights,
  tideStationMetadata,
  formatHourMin,
  settings,
  selectedStation,
  drawerData,
  changeUnits,
  setDataPanelOpen,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { unit, displayName } = mapLayers.find(
    (e) => e.variableName === selectedVariableName
  );

  const scrollDate = new Date(scrollTime);
  const insightsData = variableInsights[selectedVariableName];

  const { displayUnits, name: unitsSelected } = getNewUnits(unit, settings.units);

  const formatNumber = formatNumberGenerator(unitsSelected);

  const yyymmdd =
    scrollDate.getFullYear() +
    '-' +
    ('0' + (scrollDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + scrollDate.getDate()).slice(-2);

  const [currentInsightsData, setCurrentInsightsData] = useState();

  useEffect(() => {
    if (
      selectedVariableName === 'seaSurfaceHeight' ||
      selectedVariableName === 'current'
    ) {
      setCurrentInsightsData(insightsData?.filter((d) => +new Date(d.date) > scrollDate));
    } else if (
      selectedVariableName === 'airTemperature' ||
      selectedVariableName === 'potentialTemperature'
    ) {
      setCurrentInsightsData(insightsData?.find((d) => d?.date === yyymmdd));
    }
  }, [selectedVariableName, scrollTime]);

  const variableData = clonedeep(varData);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '500px',
        height: mobileView ? null : '100%',
        border: `2px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '15px',
        position: 'relative',
        padding: '5px 10px',
      }}
      color="secondary"
    >
      {
        <>
          <Box
            sx={{
              position: 'absolute',
              bottom: '-11px',
              left: `calc(50% - 9px)`,
              pointerEvents: 'none',
              zIndex: 100,
              width: 0,
              height: 0,
              borderLeft: `10px solid transparent`,
              borderRight: `10px solid transparent`,
              borderTop: `10px solid ${theme.palette.secondary.main}`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '-8px',
              left: `calc(50% - 7px)`,
              pointerEvents: 'none',
              zIndex: 100,
              width: 0,
              height: 0,
              borderLeft: `8px solid transparent`,
              borderRight: `8px solid transparent`,
              borderTop: `8px solid ${theme.palette.primary.main}`,
            }}
          />
        </>
      }
      <Stack justifyContent="space-between" sx={{ height: '100%' }}>
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ marginBottom: '5px', color: theme.palette.secondary.main }}
        >
          {selectedVariableName === 'seaSurfaceHeight'
            ? 'Tides: ' + truncateText(tideStationMetadata?.title, 25)
            : displayName}
        </Typography>
        <Divider
          sx={{
            width: '100%',
            borderColor: theme.palette.primary.dark,
            margin: '5px 0px',
          }}
        />

        <DataElementStrip
          selectedVariableName={selectedVariableName}
          variableData={{
            high: Number(currentInsightsData?.high),
            low: Number(currentInsightsData?.low),
            value: variableData?.value,
            direction: variableData?.direction,
            gust: variableData?.gust,
            period: variableData?.period,
            change: variableData?.change,
          }}
          dataLoading={false}
          changeUnits={changeUnits}
          minTide={tideStationMetadata?.min_tide}
          maxTide={tideStationMetadata?.max_tide}
          settings={settings}
          width="100%"
          backgroundColor={theme.palette.primary.main}
          setDataPanelOpen={setDataPanelOpen}
          showForecastButton={false}
        />
        {selectedVariableName === 'wave' && !selectedStation && (
          <>
            <Divider
              sx={{
                width: '100%',
                borderColor: theme.palette.primary.dark,
                margin: '10px 0px',
              }}
            />
            <Stack>
              <VariableInsightBottom
                formatNumber={formatNumber}
                variableData={variableData}
                currentInsightsData={currentInsightsData}
                selectedVariableName={selectedVariableName}
                selectedStation={selectedStation}
                unit={displayUnits}
                formatHourMin={formatHourMin}
                settings={settings}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
}
