import { createTheme } from '@mui/material/';
export const theme = {
  palette: {
    // https://m2.material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=BDBDBD&primary.color=0D47A1
    primary: {
      // light: '#4d6c78',
      // main: '#30505b',
      // dark: '#032b39',
      // light: '#435b67',
      // main: '#2a373f',
      // dark: '#1d262b',
      // light: '#afafaf',
      // main: '#626262',
      // dark: '#333333',
      // light: '#878d92',
      // main: '#21374b', //'#27363e',
      // dark: '#1d1e1e',
      light: '#195f84',
      main: '#0f5073',
      dark: '#023555',
    },
    secondary: {
      main: '#efefef',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#009485',
    },
    icons: {
      buoys: {
        light: '#80e27e',
        main: '#4caf50',
        dark: '#087f23',
      },
      webcams: {
        light: '#ffd149',
        main: '#ffa000',
        dark: '#c67100',
      },
      tide_stations: {
        light: '#ff6f60',
        main: '#e53935',
        dark: '#ab000d',
      },
      currents_stations: {
        light: '#ff6f60',
        main: '#e53935',
        dark: '#ab000d',
      },
      weather_stations: {
        light: '#d05ce3',
        main: '#9c27b0',
        dark: '#6a0080',
      },
    },
    // contrastThreshold: 3 // for contrast between text and background, between 3 - 4.5
    // tonalOffset: 0.2, // for generating light and dark colour variants, between 0 - 1
  },
};
export default createTheme(theme);
