// https://d3js.org/d3-axis/ v3.0.0 Copyright 2010-2021 Mike Bostock
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined'
    ? factory(exports)
    : typeof define === 'function' && define.amd
    ? define(['exports'], factory)
    : ((global = typeof globalThis !== 'undefined' ? globalThis : global || self),
      factory((global.d3 = global.d3 || {})));
})(this, function (exports) {
  'use strict';

  function identity(x) {
    return x;
  }

  const top = 1;
  const right = 2;
  const bottom = 3;
  const left = 4;
  const epsilon = 1e-6;

  function translateX(x) {
    return 'translate(' + x + ',0)';
  }

  function translateY(y) {
    return 'translate(0,' + y + ')';
  }

  function number(scale) {
    return (d) => +scale(d);
  }

  function center(scale, offset) {
    offset = Math.max(0, scale.bandwidth() - offset * 2) / 2;
    if (scale.round()) offset = Math.round(offset);
    return (d) => +scale(d) + offset;
  }

  function entering() {
    return !this.__axis;
  }

  function axis(orient, scale) {
    let tickArguments = [];
    let tickValues = null;
    let tickFormat = null;
    let tickSizeInner = 6;
    let tickSizeOuter = 6;
    let tickPadding = 3;
    let tickSizeFunction = null;
    let offset = typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 0 : 0.5;
    const k = orient === top || orient === left ? -1 : 1;
    const x = orient === left || orient === right ? 'x' : 'y';
    const transform = orient === top || orient === bottom ? translateX : translateY;

    function axis(context) {
      const values =
        tickValues == null
          ? scale.ticks
            ? scale.ticks.apply(scale, tickArguments)
            : scale.domain()
          : tickValues;
      const format =
        tickFormat == null
          ? scale.tickFormat
            ? scale.tickFormat.apply(scale, tickArguments)
            : identity
          : tickFormat;
      const spacing = Math.max(tickSizeInner, 0) + tickPadding;
      const range = scale.range();
      const range0 = +range[0] + offset;
      const range1 = +range[range.length - 1] + offset;
      const position = (scale.bandwidth ? center : number)(scale.copy(), offset);
      const selection = context.selection ? context.selection() : context;
      let path = selection.selectAll('.domain').data([null]);
      let tick = selection.selectAll('.tick').data(values, scale).order();
      let tickExit = tick.exit();
      const tickEnter = tick.enter().append('g').attr('class', 'tick');
      let line = tick.select('line');
      let text = tick.select('text');

      path = path.merge(
        path
          .enter()
          .insert('path', '.tick')
          .attr('class', 'domain')
          .attr('stroke', 'currentColor')
      );

      tick = tick.merge(tickEnter);

      line = line.merge(
        tickEnter
          .append('line')
          .attr('stroke', 'currentColor')
          .attr(x + '2', k * tickSizeInner)
      );

      text = text.merge(
        tickEnter
          .append('text')
          .attr('fill', 'currentColor')
          .attr(x, function (d, i, nodes) {
            return tickSizeFunction == null
              ? k * spacing
              : k * spacing + tickSizeFunction(d, i, nodes);
          })
          .attr('dy', orient === top ? '0em' : orient === bottom ? '0.71em' : '0.32em')
      );

      if (context !== selection) {
        path = path.transition(context);
        tick = tick.transition(context);
        line = line.transition(context);
        text = text.transition(context);

        tickExit = tickExit
          .transition(context)
          .attr('opacity', epsilon)
          .attr('transform', function (d) {
            return isFinite((d = position(d)))
              ? transform(d + offset)
              : this.getAttribute('transform');
          });

        tickEnter.attr('opacity', epsilon).attr('transform', function (d) {
          let p = this.parentNode.__axis;
          return transform((p && isFinite((p = p(d))) ? p : position(d)) + offset);
        });
      }

      tickExit.remove();

      path.attr(
        'd',
        orient === left || orient === right
          ? tickSizeOuter
            ? 'M' +
              k * tickSizeOuter +
              ',' +
              range0 +
              'H' +
              offset +
              'V' +
              range1 +
              'H' +
              k * tickSizeOuter
            : 'M' + offset + ',' + range0 + 'V' + range1
          : tickSizeOuter
          ? 'M' +
            range0 +
            ',' +
            k * tickSizeOuter +
            'V' +
            offset +
            'H' +
            range1 +
            'V' +
            k * tickSizeOuter
          : 'M' + range0 + ',' + offset + 'H' + range1
      );

      tick.attr('opacity', 1).attr('transform', function (d) {
        return transform(position(d) + offset);
      });

      line.attr(x + '2', tickSizeFunction == null ? k * tickSizeInner : tickSizeFunction);

      text
        .attr(x, function (d, i, nodes) {
          return tickSizeFunction == null
            ? k * spacing
            : k * spacing + tickSizeFunction(d, i, nodes);
        })
        .text(format);

      selection
        .filter(entering)
        .attr('fill', 'none')
        .attr('font-size', 10)
        .attr('font-family', 'sans-serif')
        .attr(
          'text-anchor',
          orient === right ? 'start' : orient === left ? 'end' : 'middle'
        );

      selection.each(function () {
        this.__axis = position;
      });
    }

    axis.scale = function (_) {
      return arguments.length ? ((scale = _), axis) : scale;
    };

    axis.ticks = function () {
      return (tickArguments = Array.from(arguments)), axis;
    };

    axis.tickArguments = function (_) {
      return arguments.length
        ? ((tickArguments = _ == null ? [] : Array.from(_)), axis)
        : tickArguments.slice();
    };

    axis.tickValues = function (_) {
      return arguments.length
        ? ((tickValues = _ == null ? null : Array.from(_)), axis)
        : tickValues && tickValues.slice();
    };

    axis.tickFormat = function (_) {
      return arguments.length ? ((tickFormat = _), axis) : tickFormat;
    };

    axis.tickSize = function (_) {
      return arguments.length
        ? typeof _ === 'function'
          ? ((tickSizeInner = tickSizeOuter = null), (tickSizeFunction = _), axis)
          : ((tickSizeFunction = null), (tickSizeInner = tickSizeOuter = +_), axis)
        : tickSizeFunction || tickSizeInner;
    };

    axis.tickSizeInner = function (_) {
      return arguments.length ? ((tickSizeInner = +_), axis) : tickSizeInner;
    };

    axis.tickSizeOuter = function (_) {
      return arguments.length ? ((tickSizeOuter = +_), axis) : tickSizeOuter;
    };

    axis.tickPadding = function (_) {
      return arguments.length ? ((tickPadding = +_), axis) : tickPadding;
    };

    axis.offset = function (_) {
      return arguments.length ? ((offset = +_), axis) : offset;
    };

    return axis;
  }

  function axisTop(scale) {
    return axis(top, scale);
  }

  function axisRight(scale) {
    return axis(right, scale);
  }

  function axisBottom(scale) {
    return axis(bottom, scale);
  }

  function axisLeft(scale) {
    return axis(left, scale);
  }

  exports.axisBottom = axisBottom;
  exports.axisLeft = axisLeft;
  exports.axisRight = axisRight;
  exports.axisTop = axisTop;

  Object.defineProperty(exports, '__esModule', { value: true });
});
