import React, { useState, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { capitalizeFirstLetter } from '../utilities';

import {
  Box,
  Dialog,
  List,
  ListItemText,
  ListItemButton,
  IconButton,
  Divider,
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
  TextField,
} from '@mui/material/';

import { Close as CloseIcon, Search } from '@mui/icons-material';

export default function SearchMenu({
  setSelectedMapElement,
  setSelectedStation,
  setflyToCoords,
  setPointLocations,
  searchOpen,
  setSearchOpen,
}) {
  const theme = useTheme();

  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  function generateCountryCode(countryCode) {
    switch (countryCode) {
      case 'US':
        return 'USA';

      case 'CA':
        return 'CAN';

      default:
        return '';
    }
  }

  function handleSearchTextChange(searchText) {
    if (!searchText.length) return;

    setSearchLoading(true);

    const bbox = '-144.56,41.123,-122,60.19';
    const limit = 10;

    fetch(`https://photon.komoot.io/api/?q=${searchText}&bbox=${bbox}&limit=${limit}`)
      .then((res) => res.json())
      .then((data) => {
        return data.features.map(({ geometry, properties }) => ({
          lat: geometry.coordinates[0],
          lon: geometry.coordinates[1],
          properties,
        }));
      })
      .then((results) => {
        const placesParsed = results.map((place) => {
          const { lat, lon, properties } = place;
          let { name, osm_value: osmValue, countrycode, type } = properties;

          if (osmValue === 'unclassified') {
            osmValue = '';
          }

          if (type === 'house') {
            name = [properties.housenumber, properties.street].filter((e) => e).join(' ');
          }

          return {
            lat,
            lon,
            name,
            type: capitalizeFirstLetter(osmValue.replace('_', ' ')),
            country: generateCountryCode(countrycode),
          };
        });

        const filteredResults = [];
        function placeIsUnique(place) {
          let isUnique = true;
          if (filteredResults.length > 0) {
            filteredResults.forEach((p) => {
              if (
                place.name === p.name &&
                place.type === p.type &&
                place.country === p.country
              ) {
                isUnique = false;
              }
            });
          }
          return isUnique;
        }
        placesParsed.forEach((place) => {
          if (placeIsUnique(place)) {
            filteredResults.push(place);
          }
        });
        setSearchResults(filteredResults);
        setSearchLoading(false);
      });
  }

  const [searchString, setSearchString] = useState('');
  const debouncedHandleSearchTextChange = useMemo(
    () => debounce(handleSearchTextChange, 300),
    []
  );

  return (
    <Dialog
      fullScreen={mobileView}
      open={searchOpen}
      sx={{ pointerEvents: 'auto' }}
      onClose={(e, reason) => {
        setSearchResults([]);

        return reason === 'backdropClick' && setSearchOpen(false);
      }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ height: '48px', padding: '5px', minWidth: mobileView ? null : '500px' }}
      >
        <Stack
          direction="row"
          sx={{ width: '100%', marginLeft: '10px' }}
          alignItems="center"
          spacing={1}
        >
          <Search />
          <TextField
            variant="filled"
            placeholder="  Search for a place..."
            value={searchString}
            onChange={(e) => {
              setSearchResults([]);
              const { value } = e.target;
              debouncedHandleSearchTextChange(value);
              setSearchString(value);
            }}
            fullWidth
            color="primary"
            autoFocus
            inputProps={{
              style: {
                color: theme.palette.secondary.main,
                height: '38px',
                padding: '2px',
                width: '100%',
              },
            }}
            sx={{ width: '100%', padding: '2px' }}
          />
        </Stack>
        <IconButton color="inherit" onClick={() => setSearchOpen(false)}>
          <CloseIcon color="secondary" />
        </IconButton>
      </Stack>
      <Divider />
      {searchLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '200px' }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : searchResults.length > 0 ? (
        <List
          sx={{
            margin: '0px 25px',
            minHeight: '200px',
            minWidth: mobileView ? '' : '400px',
          }}
        >
          {searchResults.map((place, index) => {
            const { lat, lon, name, type, country } = place;
            return (
              <React.Fragment key={index}>
                <ListItemButton
                  onClick={() => {
                    setSelectedMapElement(place);
                    setSelectedStation();
                    setflyToCoords([lat, lon]);
                    setPointLocations([lat, lon]);
                    setSearchOpen(false);
                    setSearchResults([]);
                    setSearchString();
                  }}
                >
                  <ListItemText sx={{ flex: 2 }}>{name}</ListItemText>
                  <ListItemText sx={{ flex: 2 }}>{type}</ListItemText>
                  <ListItemText sx={{ flex: 1 }}>{country}</ListItemText>
                </ListItemButton>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '200px' }}
        >
          No search results found
        </Box>
      )}
    </Dialog>
  );
}
