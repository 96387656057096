import React from 'react';
import { Box } from '@mui/material';
import { VerticalTrademark } from './VerticalTrademark.jsx';

export default function Spinner() {
  const scaleFactor = 1.4;
  const boxWidth = 100 * scaleFactor;
  const boxHeight = 210 * scaleFactor;
  const animationWidth = 300 * scaleFactor;
  const animationHeight = 300 * scaleFactor;
  const animationTop = -120 * scaleFactor;
  const animationLeft = -100 * scaleFactor;

  return (
    <Box
      sx={{
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
        backgroundColor: '#0f5073',
        borderRadius: '10px',
        position: 'relative',
        boxShadow: '0px 5px 10px 0px rgba(0,0,0,.3)',
        overflow: 'hidden',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          width: `${animationWidth}px`,
          height: `${animationHeight}px`,
          top: `${animationTop}px`,
          left: `${animationLeft}px`,
          backgroundColor: '#efefef',
        },
        '&::after': {
          borderRadius: '37%',
          background: 'rgba(255,255,255,.1)',
          animation: 'wave1 4s linear infinite',
        },
        '&::before': {
          borderRadius: '35%',
          background: 'rgba(255,255,255,.1)',
          animation: 'wave2 5s linear infinite',
        },
        '@keyframes wave2': {
          '0%': {
            transform: 'rotate(0)',
          },
          '100%': {
            transform: 'rotate(-360deg)',
          },
        },
        '@keyframes wave1': {
          '0%': {
            transform: 'rotate(45deg)',
          },
          '100%': {
            transform: 'rotate(-315deg)',
          },
        },
      }}
    >
      <Box sx={{ margin: '6px' }}>
        <VerticalTrademark />
      </Box>
    </Box>
  );
}
