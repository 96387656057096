// you can control category order and unit display order here
const units = [
  {
    name: 'kmh',
    display: 'km/h',
    category: 'speed',
    multiplier: (x) => x,
    decimals: false,
  },
  {
    name: 'mph',
    display: 'mph',
    category: 'speed',
    multiplier: (x) => x / 1.6,
    decimals: true,
  },
  {
    name: 'kts',
    display: 'knots',
    category: 'speed',
    multiplier: (x) => x / 1.852,
    decimals: true,
  },
  {
    name: 'c',
    display: '°C',
    category: 'temperature',
    multiplier: (x) => x,
    decimals: false,
  },
  {
    name: 'f',
    display: '°F',
    category: 'temperature',
    multiplier: (x) => (9 / 5) * x + 32,
    decimals: false,
  },
  { name: 'm', display: 'm', category: 'height', multiplier: (x) => x, decimals: true },
  {
    name: 'ft',
    display: 'ft',
    category: 'height',
    multiplier: (x) => x * 3.28084,
    decimals: false,
  },
];
export default units;
