import React from 'react';
import { Box } from '@mui/material';

export default function Crosshair({ crosshairWidth, ringColor, pipColor }) {
  return (
    <Box
      sx={{
        width: `${crosshairWidth}px`,
        height: `${crosshairWidth}px`,
        transform: 'rotate(-45deg)',
        borderRadius: '2px 2px 2px 2px',
      }}
    >
      <svg
        viewBox={`0 0 ${crosshairWidth * 2} ${crosshairWidth * 2}`}
        width={`${crosshairWidth}px`}
        style={{ filter: 'drop-shadow(-2px 2px 2px #00000025)' }}
      >
        <defs>
          <mask
            id="mask"
            x="0"
            y="0"
            width={crosshairWidth * 2}
            height={crosshairWidth * 2}
          >
            <rect
              x="0"
              y="0"
              width={crosshairWidth * 2}
              height={crosshairWidth * 2}
              fill="#fff"
            />
            <circle cx={crosshairWidth} cy={crosshairWidth} r={crosshairWidth * 0.8} />
          </mask>
        </defs>
        <rect
          x="0"
          y="0"
          width={crosshairWidth * 2}
          height={crosshairWidth * 2}
          mask="url(#mask)"
          fillOpacity="1"
          fill={ringColor}
          rx={crosshairWidth / 3}
        />
        <circle
          cx={crosshairWidth}
          cy={crosshairWidth}
          r={crosshairWidth / 5}
          fill={pipColor}
        />
      </svg>
    </Box>
  );
}
