import { Button, Skeleton, styled } from '@mui/material';

export const StyledDrawerVariableSwitchButton = styled(Button)(({ theme }) => ({
  height: '35px',
  width: '60px',
  minWidth: '40px',
  margin: 'auto 5px',
  color: 'white',
  borderRadius: '30px',
}));

export const StyledDrawerHeaderButton = styled(Button)(({ theme }) => ({
  pointerEvents: 'auto',
  borderRadius: '30px',
  height: '30px',
  border: '2px solid #ffffff00',
  backgroundColor: `${theme.palette.primary.light}BF`,
  textTransform: 'none',
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  '::after': {
    background: 'linear-gradient(90deg, #ffffff00, #ffffff15, #ffffff00)',
    animation: 'animation-wiooy9 0.8s linear 0.0s infinite',
  },
}));
