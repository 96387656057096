import React from 'react';
import mapLayers from '../../mapLayers';
import {
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { getNewUnits, formatNumberGenerator } from '../../utilities.js';

export default function DataGrid({
  data,
  variableNameSelected,
  setSelectedVariableName,
  variablesInPanel,
  settings,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.dark,
        minWidth: mobileView ? '100%' : '50%',
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        rowSpacing={mobileView ? 1 : 2}
        columnSpacing={mobileView ? 1 : 2}
      >
        {data &&
          variablesInPanel.map((variableName, index) => {
            const { displayName, unit } = mapLayers.find(
              (e) => e.variableName === variableName
            );

            const { displayUnits, name: unitsSelected } = getNewUnits(
              unit,
              settings.units
            );
            const formatNumber = formatNumberGenerator(unitsSelected);
            const { value } = data[variableName] || {};
            return (
              <React.Fragment key={index}>
                <Grid item key={index} sx={{ boxSizing: 'border-box' }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100px',
                      border:
                        variableName === variableNameSelected
                          ? '2px solid white'
                          : '2px solid #000000000',
                      backgroundColor:
                        variableName === variableNameSelected
                          ? theme.palette.primary.dark
                          : theme.palette.primary.main,
                      borderRadius: '15px',
                      boxShadow: 'none',
                      paddingLeft: '5px',
                    }}
                    onClick={(variable) => {
                      window.clarity('event', 'Datapanel change variable');
                      setSelectedVariableName(variableName);
                    }}
                  >
                    <Stack alignItems="center">
                      <Stack direction="row" sx={{ height: '24px' }}>
                        <Typography
                          color="secondary"
                          sx={{
                            marginLeft: 2,
                            flex: 1,
                            textTransform: 'none',
                            fontSize: 12,
                          }}
                          variant="button"
                        >
                          {isNaN(formatNumber(value)) ? 'no data' : formatNumber(value)}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ padding: '0px 0px 5px 5px', textTransform: 'none' }}
                        >
                          {value !== undefined && value !== null && displayUnits}
                        </Typography>
                      </Stack>
                      <Typography
                        color="secondary"
                        variant="caption"
                        component="div"
                        textAlign="center"
                        sx={{ width: '100%' }}
                      >
                        {displayName.slice(0, 8)}
                      </Typography>
                    </Stack>
                  </Button>
                </Grid>
              </React.Fragment>
            );
          })}
      </Grid>
    </Box>
  );
}
