import React from 'react';
import { Box, LinearProgress } from '@mui/material';

export default function TideIndicator({
  maxTide,
  minTide,
  currentTide,
  height = '30px',
  width = '20px',
}) {
  const VerticalLinearProgress = ({
    value,
    height = 30,
    width = 20,
    color = '#5cb6ff',
  }) => {
    return (
      <Box
        sx={{
          height,
          width,
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: 1,
            backgroundColor: '#FFFFFF', // Set the background color to white

            '& .MuiLinearProgress-bar': {
              transform: `translateY(${100 - value}%) !important`,
              transformOrigin: 'bottom',
              backgroundColor: color, // Set the color here
            },
          }}
        />
      </Box>
    );
  };

  const minMaxDifference = maxTide - minTide;
  let normCurrentTide = currentTide - minTide;

  if (normCurrentTide <= 0) {
    normCurrentTide = minTide;
  } else if (normCurrentTide >= maxTide) {
    normCurrentTide = maxTide;
  }

  const currentTidePercent = (normCurrentTide / minMaxDifference) * 100;

  return (
    <Box
      sx={{
        position: 'relative',
        height,
        width,
      }}
    >
      <VerticalLinearProgress value={currentTidePercent} />
    </Box>
  );
}
