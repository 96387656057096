// order is relevant to how they are layered on map.
const stationTypes = [
  {
    type: 'webcams',
    typeName: 'Webcam',
    icon: 'Webcam_Station.png',
    displayName: 'Webcams',
  },
  { type: 'buoys', typeName: 'Buoy', icon: 'Buoy_Station.png', displayName: 'Buoys' },
  {
    type: 'weather_stations',
    typeName: 'Weather station',
    icon: 'Weather_Station.png',
    displayName: 'Weather Stations',
  },
  {
    type: 'tide_stations',
    typeName: 'Tide station',
    icon: 'Tide_Station.png',
    displayName: 'Tide Stations',
  },
  {
    type: 'currents_stations',
    typeName: 'Current station',
    icon: 'Current_Station.png',
    displayName: 'Current Stations',
  },
];
export default stationTypes;
