import React from 'react';
import { Stack, Grid, Box, Typography } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';

import { daysOfTheWeekShortName, getArrowDirection } from '../../utilities.js';

function VariableInsightBottom({
  variableData,
  selectedVariableName,
  formatNumber,
  unit,
}) {
  let arrowDirection;
  if (variableData?.secondary_direction !== undefined) {
    arrowDirection = getArrowDirection(
      selectedVariableName,
      variableData?.secondary_direction
    );
  }

  function generateSwellStack(variableData) {
    const hasData =
      !isNaN(variableData?.secondary_height) &&
      !isNaN(formatNumber(variableData?.secondary_height)) &&
      arrowDirection != null &&
      typeof arrowDirection === 'number' &&
      !isNaN(formatNumber(variableData?.secondary_period));
    return (
      <Grid container direction="row">
        <Grid item container xs={4} justifyContent="start">
          <Typography variant="caption">Secondary swell:</Typography>
        </Grid>
        {hasData ? (
          <>
            <Grid item container xs={3} justifyContent="center">
              <Typography variant="caption">
                {`${formatNumber(variableData?.secondary_height)} ${unit}`}
              </Typography>
            </Grid>
            <Grid item container xs={3} justifyContent="center">
              <Typography variant="caption">
                {`${variableData?.secondary_period?.toFixed(0)}s`}
              </Typography>
            </Grid>
            <Grid item container xs={2} justifyContent="center">
              <ArrowUpward
                color="secondary"
                sx={{
                  transform: `rotate(${arrowDirection}deg)`,
                  width: '20px',
                  height: '20px',
                }}
              />
            </Grid>
          </>
        ) : (
          <Grid item container xs={4} justifyContent="center">
            <Typography variant="caption">no data</Typography>
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <Stack justifyContent="space-evenly" alignItems="center">
      {selectedVariableName === 'wave' ? <>{generateSwellStack(variableData)}</> : <></>}
    </Stack>
  );
}

export default VariableInsightBottom;
