import React from 'react';
import { Box, Typography } from '@mui/material';
import VerticalLogo from '../Images/oceanconnectlogo-color-03.svg';

export function VerticalTrademark() {
  return (
    <Box sx={{ position: 'relative', padding: '5px', width: '100%' }}>
      <Typography
        color="secondary"
        sx={{
          cursor: 'default',
          lineHeight: 0.5,
          position: 'absolute',
          top: '5px',
          right: '-95%',
          width: '100%',
          height: '0px',
        }}
      >
        &trade;
      </Typography>
      <Box component="img" src={VerticalLogo} width="100%" />
    </Box>
  );
}
