import React from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import MailchimpForm from './MailchimpForm.jsx';

export default function MailchimpModal({
  open,
  setNewsletterModalOpen,
  setHideNewsletterModalPermanently,
}) {
  const theme = useTheme();
  const [hideNewsletterToggle, setHideNewsletterToggle] = React.useState(false);

  function closeModal() {
    setNewsletterModalOpen(false);
    setHideNewsletterModalPermanently(hideNewsletterToggle);
    window.clarity('event', `Newsletter closed permanently: ${hideNewsletterToggle}`);
  }
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          closeModal();
        }
      }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      }}
    >
      <Toolbar>
        <Typography
          color="secondary"
          sx={{ ml: 1, flex: 1 }}
          variant="h6"
          component="div"
        >
          Stay informed!
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => closeModal()}
          aria-label="close"
        >
          <Close color="secondary" />
        </IconButton>
      </Toolbar>
      <Divider />
      <Typography
        color="secondary"
        variant="body1"
        sx={{ padding: '10px 20px 0px 20px' }}
      >
        Sign up for feature announcements, service updates, and more.
      </Typography>
      <MailchimpForm signupMode="popup modal" />
      <Box sx={{ paddingLeft: '10px' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={hideNewsletterToggle}
                onChange={() => setHideNewsletterToggle(!hideNewsletterToggle)}
                sx={{
                  color: hideNewsletterToggle
                    ? theme.palette.primary.dark
                    : theme.palette.secondary.main,
                  '&.Mui-checked': {
                    color: theme.palette.secondary.main,
                  },
                }}
              />
            }
            label="Don't show this again"
            color="secondary"
          />
        </FormGroup>
      </Box>
    </Dialog>
  );
}
