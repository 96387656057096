import React from 'react';
import { styled } from '@mui/material/styles';

import { Icon } from '@mui/material/';

const iHeight = '50px';
const iWidth = '25px';
const StyledMUIIcon = styled(Icon)(({ theme }) => ({
  height: '2em',
  width: 'fit-content',
  color: theme.palette.secondary.main,
}));

export default function StyledIcon({
  filename,
  height = iHeight,
  width = iWidth,
  style = {},
}) {
  if (filename) {
    return (
      <StyledMUIIcon sx={style}>
        <img src={require('../Images/' + filename)} height={height} width={width} />
      </StyledMUIIcon>
    );
  }
}
